import styled from "styled-components";
import { colors } from "../../../../shared/ui/colors";
import { H1 } from "shared/ui/headings";
import { prop } from "ramda";
import { Container } from "../../../../shared/ui/container";

const tabletBreak = `max-width: 1080px`
const mobileBreak = `max-width: 768px`

export const Root = styled(Container)`
  background: ${colors.primaryDark};
  color: ${colors.white};
  width: 100%;
  display: flex;
  align-items: center;

  @media screen and (${tabletBreak}) {
    align-items: stretch;
  }
`;

export const Content = styled.div`
  padding: 30px 0;
  flex-grow: 1;
  @media screen and (${mobileBreak}) {
    padding: 15px 0;
  }
`;

export const Title = styled(H1)`
  font-weight: 700;
  &:after {
    content: "";
    height: 2px;
    background: white;
    width: 50px;
    margin: 25px 0;
    display: block;
  }
  @media screen and (${mobileBreak}) {
    &:after {
      margin: 15px 0;
    }
  }
`;

export const KeyDetails = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: ${(props) =>
    props.$fullWidth ? "space-between" : "flex-start"};
  
  @media screen and (${mobileBreak}) {
    margin-bottom: -25px;
    flex-wrap: wrap;
  }
`;

export const Detail = styled.div`
  display: block;
  min-width: calc(100% / 3 - 20px);
  font-weight: 700;
  
  &:before {
    content: "${prop("title")}";
    font-size: 16px;
    font-weight: 300;
    margin-bottom: ${(props) => (props.$compact ? "0" : "10px")};
    display: block;
  }
  font-size: 24px;
  margin-right: 20px;

  @media screen and (${mobileBreak}) {
    ${({ $hideMobile }) => $hideMobile ? 'display: none;': ''}
    min-width: calc(50% - 20px);
    margin-bottom: 25px;
    font-size: 14px;
    font-weight: 400;
    &:before {
      font-size: 12px;
      margin-bottom: ${(props) => (props.$compact ? "0" : "5px")};
    }
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: calc(100% / 3);
  padding-top: calc(100% / 4);
  overflow: hidden;
  margin-left: 100px;

  &:before,
  &:after {
    content: "";
    z-index: 2;
    position: absolute;
    width: 50%;
    height: 200%;
    background: ${colors.primaryDark};
    transform: rotate(15deg);
  }
  &:before {
    bottom: 0;
    right: 100%;
    transform-origin: bottom right;
  }
  &:after {
    top: 0;
    left: 100%;
    transform-origin: top left;
  }
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  @media screen and (${tabletBreak}) {
    margin-left: 0;
    margin-right: -12px;
    &:after {
      display: none;
    }
  }

`;
