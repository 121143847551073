import React from 'react'
import { Gap } from "shared/ui/gap";
import { P } from "shared/ui/headings";
import { Grid } from "shared/ui/grid";
import GetAppIcon from "@material-ui/icons/GetApp";
import { PlainLink } from "shared/ui/button";
import { useListing } from '../context';

const Documents = () => {
  const { documents } = useListing()
  if (!documents || documents.length === 0) return null
  return <>
    <P>
      <b>Documents</b>
    </P>
    <Gap x={0.5} />
    <div>
      {documents.map((doc) => (
        <PlainLink key={doc.src} href={doc.src} target="_blank">
          <Grid>
            <GetAppIcon />
            <Gap x={0.25} />
            <div>
              {doc.title.split(".")[0]} ({doc.title.split(".")[1]})
            </div>
          </Grid>
        </PlainLink>
      ))}
    </div>
    <Gap x={2} />
  </>
}

export default Documents