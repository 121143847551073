import { propOr } from "ramda";
import styled from "styled-components";

const gutter = 24;

export const Cell = styled.div`
  display: block;
  overflow: ${({ $visibleOverflow }) => $visibleOverflow ? 'visible' : 'hidden'};
  max-width: calc(100% / 12 * ${({ $s }) => $s || 12});
  flex: ${({ $s }) => ($s ? `calc(100% / 12 * ${$s})` : "auto")} 0 1;
`;

export const Grid = styled.div`
  display: flex;
  align-items: ${({ $align }) => ($align ? $align : "center")};
  width: calc(100% + ${(props) => (props.$noPadding ? 0 : gutter * 2)}px);
  padding: 0 ${(props) => (props.$noPadding ? 0 : gutter)}px;
  margin: 0 -${(props) => (props.$noPadding ? 0 : gutter)}px;
  flex-wrap: ${({ $wrap }) => ($wrap ? "wrap" : "no-wrap")};
  & > ${/* sc-sel */ Cell} {
    padding: ${(props) => (props.$noPadding ? 0 : gutter)}px;
  }
  @media screen and (max-width:768px) {
    & > ${/* sc-sel */ Cell} {
      padding: ${gutter / 2}px;
    }
  }
  @media screen and (max-width:${propOr(0, '$breakAt')}px) {
    flex-wrap: wrap;
    & > ${/* sc-sel */ Cell} {
      flex: 100% 1 1;
      max-width: 100%;
    }
  }
`;
