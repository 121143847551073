import { useEffect } from "react";
import { useFirebase } from "react-redux-firebase";
import Cookies from "js-cookie";

export const useAuthChange = () => {
  const firebase = useFirebase();
  // Checks that user state has changed and then creates or destroys cookie with Firebase token.
  const onAuthStateChange = () => {
    return firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken();
        Cookies.set("__session", token, { expires: 14 });
      } else {
        Cookies.remove("__session");
      }
    });
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChange();
    return () => {
      unsubscribe();
    };
  }, []);
};
