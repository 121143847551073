import { colors } from "shared/ui/colors";
import styled from "styled-components";
import { CountdownRoot } from "../bid-banner/styled";

export const ModalContent = styled.div`
  max-width: 600px;
  width: 100%;

  ${CountdownRoot} {
    border-top: none;
  }
`;

export const ModalBody = styled.div`
  padding: 24px;
`;

export const ModalHeader = styled.div`
  background: ${colors.primary};
  padding: 24px;
  text-align: left;
  color: ${colors.white};
`;
