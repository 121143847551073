import React from 'react'
import { P } from "shared/ui/headings";
import SellerInfo from "shared/ui/seller-info";
import { Gap } from "shared/ui/gap";
import { useListing } from '../context';

const Seller = () => {
  const { owner } = useListing()
  return <>
    <P>
      <b>Seller info</b>
    </P>
    <SellerInfo seller={owner} />
    <Gap x={2} />
  </>
}

export default Seller