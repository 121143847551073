import React from "react";
import { Grid } from "shared/ui/grid";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import { Gap } from "shared/ui/gap";

const ReliableBadge = (props) => {
  if (!props.show) return null;

  return (
    <Grid>
      <TrendingUpIcon />
      <Gap x={0.5} />
      <div>The performance data shown is correct and reliable</div>
    </Grid>
  );
};

export default ReliableBadge;
