import styled from "styled-components";
import { colors } from "../colors";

export const TabPanel = styled.div`
  position: relative;
  overflow-x: hidden;
`;

export const TabWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const Tab = styled.div.attrs({
  role: "tab",
  tabIndex: 0
})`
  cursor: pointer;
  padding: 10px 20px;
  border-bottom: 2px solid ${props => props.selected ? colors.primary : colors.transparent};
  &:focus {
    outline: none;
  }
`;

