import React, { useRef } from "react";
import styled, { css } from "styled-components";
import PersonIcon from "@material-ui/icons/Person";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { PlainButton } from "../../../shared/ui/button";
import Modal from "../../../shared/ui/modal";
import { useCurrentUser } from "../../../shared/hooks/use-current-user";
import { Link, useHistory } from "react-router-dom";
import { colors } from "../../../shared/ui/colors";
import LoginForm from "../../pages/login/form";
import { H2, SmallP } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";

const Root = styled.div`
  position: relative;
  background: ${colors.primary};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TriggerButton = styled(Link)`
  color: ${colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 100%;
  @media screen and (max-width: 480px) {
    width: 48px;
  }
`;
const UserDisplayName = styled.span`
  margin-left: 5px;
  &:empty {
    display: none;
  }
`;
export const ModalWrapper = styled.div`
  padding: 32px 32px 24px;
`;

const UserBox = (props) => {
  const { auth, profile } = useCurrentUser();
  const history = useHistory();
  const modalRef = useRef(null);
  const handleButtonClick = (e) => {
    if (!auth?.uid) {
      e.preventDefault();
      modalRef.current?.open();
    }
  };
  const Icon = auth?.uid ? AccountCircleIcon : PersonIcon;
  const onLogin = () => {
    history.go(0);
  };

  return (
    <Root>
      <TriggerButton to={"/my"} onClick={handleButtonClick}>
        <Icon />
        {false && (
          <UserDisplayName>
            {profile?.displayName?.split(" ")[0]}
          </UserDisplayName>
        )}
      </TriggerButton>
      <Modal initiallyOpen={false} ref={modalRef}>
        <ModalWrapper>
          <H2>Sign in</H2>
          <Gap />
          <LoginForm onLogin={onLogin} />
          <Gap x={0.5} />
          {false && <SmallP>
            Or{" "}
            <Link to={"/signup"} onClick={modalRef.current?.close}>
              create an account
            </Link>
          </SmallP>}
        </ModalWrapper>
      </Modal>
    </Root>
  );
};

export default UserBox;
