import React, { useEffect, useState } from "react";
import intervalToDuration from "date-fns/intervalToDuration";
import { P } from "shared/ui/headings";
import { EndDate, CountdownRoot } from "./styled";
import { humanizeDate } from "./helper";

const Countdown = (props) => {
  const [_, tick] = useState(new Date());

  const duration = intervalToDuration({
    start: new Date(),
    end: new Date(props.endDate),
  });

  useEffect(() => {
    const tout = setTimeout(() => {
      tick(new Date());
    }, duration.seconds * 1000);
    return () => clearTimeout(tout);
  }, [duration]);

  const { remaining, endDay } = humanizeDate(props.endDate);

  return (
    <CountdownRoot>
      <P>
        <b>Time left: </b>
        {remaining}
        <EndDate>{endDay}</EndDate>
      </P>
    </CountdownRoot>
  );
};

export default Countdown;
