import {
  Diploma,
  Like,
  Shield,
  Trophy,
  Clock,
  DuplicateWindow,
  LifeJacket,
} from "shared/icons";

export const logos = [
  {
    url: "https://spreadshirt.com",
    img: "/images/features/spread_shirt.jpg",
  },
  {
    url: "https://merch.amazon.com",
    img: "/images/features/amazon_merch.jpg",
  },
  {
    url: "https://www.redbubble.com",
    img: "/images/features/red_bubble.jpg",
  },
  {
    url: "https://www.shirtee.com",
    img: "/images/features/shirtee.jpg",
  },
  {
    url: "https://www.teepublic.com",
    img: "/images/features/teepublic.jpg",
  },
  {
    url: "https://www.society6.com",
    img: "/images/features/society6.jpg",
  },
];

export const featuresOne = [
  {
    Component: Diploma,
    title: "High testing standards",
    description:
      "Every account listed on Flipstorm has been carefully checked beforehand and meets our high standards.",
  },
  {
    Component: Like,
    title: "We take you by the hand",
    description:
      "Optimized processes and personal support enable a smooth purchase.",
  },
  {
    Component: Shield,
    title: "We take care of you",
    description:
      "As an intermediary, we monitor the purchase process and enable a fair transaction.",
  },
  {
    Component: Trophy,
    title: "We know how things are going",
    description:
      "We are eCommerce veterans and know the print-on-demand business inside and out.",
  },
];
export const featuresTwo = [
  {
    Component: Clock,
    title: "We flip them (almost) all!",
    description:
      "Whether Spreadshirt, Merch by Amazon, Shirtee, Redbubble, Teepublic or Society6. We flip almost everything.",
  },
  {
    Component: DuplicateWindow,
    title: "Valuation",
    description:
      "We help you with the fair evaluation of your accounts. We use your numbers as well as our wealth of experience.",
  },
  {
    Component: LifeJacket,
    title: "We'll find the right buyer",
    description:
      "Finding a reliable buyer is one of the biggest challenges when selling accounts. We do it for you.",
  },
];
