import React from "react";
import { Container } from "@material-ui/core";
import Helmet from "react-helmet";
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "../../../shared/hooks/use-current-user";
import LoginForm from "./form";

const Login = () => {
  const history = useHistory();
  const { auth } = useCurrentUser();
  if (auth?.uid) {
    history.push("/");
  }
  return (
    <Container maxWidth="sm">
      <Helmet>
        <title>Login</title>
      </Helmet>
      <h1>Login</h1>
      <LoginForm onLogin={() => history.go(0)} />
    </Container>
  );
};

export default Login;
