import styled from "styled-components";

export const StyledFooter = styled.footer`
  background: white;
  width: 100%;
  padding: 50px calc(50% - 600px);
`;

export const Logo = styled.img`
  max-height: 25px;
  height: 100%;
`;
