import React from "react";
import { Grid, Cell } from "shared/ui/grid";
import { keys, T, anyPass, pathEq } from "ramda";
import {
  ListingCard,
  ListingImage,
  ListingPrice,
  ListingButton,
} from "./styled";
import { P, SmallP, Note } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";
import { formatCurrency } from "../../../../shared/formatters/currency";

const Listings = (props) => {
  const typeFilters = keys(props.filters.types)
    .filter((k) => props.filters.types[k])
    .map((k) => pathEq(["type", "id"], k));

  const filteredListings = props.listings.filter(
    typeFilters.length > 0 ? anyPass(typeFilters) : T
  );

  return (
    <Grid $wrap $align="stretch">
      {filteredListings.map((listing) => (
        <Cell $s={6} key={listing.id}>
          <ListingCard to={`listing/${listing.id}`}>
            <ListingImage src="https://picsum.photos/100" />
            <P>{listing.name}</P>
            <SmallP>
              <b>{listing.type.name}</b>
            </SmallP>
            <SmallP>{listing.description}</SmallP>
            {listing.topBid && (
              <ListingPrice>
                {formatCurrency(listing.topBid.value, listing.topBid.currency)}
              </ListingPrice>
            )}
            <Gap x={0.5} />
            <ListingButton>View Listing</ListingButton>
          </ListingCard>
        </Cell>
      ))}
    </Grid>
  );
};

export default Listings;
