import React from 'react'
import { colors } from 'shared/ui/colors'
import styled from 'styled-components'
import HelpIcon from "@material-ui/icons/Help";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { UsefulLinks } from "./styled";
import { Gap } from "shared/ui/gap";
import {  InlineButton } from "shared/ui/button";
import { withModalManagement } from "shared/modal-manager";
import { LISTING_MODALS } from "../modals/constants";
import { useListing } from '../context';
import VerifiedBadge from "../badges/verified";
import ReliableBadge from "../badges/reliable";

const Root = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1080px) {
    flex-direction: column-reverse;
    background: ${colors.primaryLight};
    border: 1px solid ${colors.midGray};
    border-radius: 4px;
    padding: 12px;
  }
`

const BiddingInfo = props => {
  const { verified, reliable } = useListing()
  return (
    <Root>
      {false && 
      <UsefulLinks>
        <InlineButton
          onClick={() => {
            props.modalActions.triggerModal(LISTING_MODALS.whatHappens);
          }}
        >
          <HelpIcon />
          <Gap x={0.25} />
          What happens after a bid?
        </InlineButton>
        {false && <InlineButton>
          <VisibilityIcon />
          <Gap x={0.25} />
          Watch this listing
        </InlineButton>}
      </UsefulLinks>}
      <Gap />
      <div>
        <VerifiedBadge show={verified} />
        <Gap />
        <ReliableBadge show={reliable} />
      </div>
    </Root>
  )
}

export default withModalManagement(BiddingInfo)