import { gql } from "@apollo/client";

export const GET_OWN_BIDS = gql`
  {
    myBids {
      id
      value
      currency
      timestamp
      status {
        name
      }
      listing {
        id
        name
        topBid {
          id
          value
          currency
        }
      }
    }
  }
`;
