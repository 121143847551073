import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useQuery } from "@apollo/client";
import { H1 } from 'shared/ui/headings'
import { PageTitle } from 'shared/ui/page-title'
import { PageContent } from 'shared/ui/page-content'
import { Gap } from 'shared/ui/gap'
import Faq from './faq'
import { GET_FAQS } from './queries'
import { useHistory, useParams } from 'react-router';
import Loading from 'shared/ui/loading';
import { propEq } from 'ramda';
import { useRouteMatch, generatePath } from 'react-router'

const FaqPage = props => {
  const { loading, error, data } = useQuery(GET_FAQS)
  const { type } = useParams()
  const history = useHistory()
  const match = useRouteMatch();

  useEffect(() => {
    if (data) {
      const selectedType = data.faqTypes.find(propEq('id', type))
      if (!selectedType) {
        history.replace({
          pathname: generatePath(match.path, { type: data.faqTypes[0].id })
        })
      }
    }
  }, [data, type])

  const onChange = index => {
    const newType = data.faqTypes[index]
    if (newType) {
      history.push({
        pathname: generatePath(match.path, { type: newType.id })
      })
    }
  }

  return (
    <section>
      <Helmet>
        <title>FAQ</title>
        <meta name="description" content="Frequently asked questions" />
      </Helmet>
      <PageTitle>
        <H1>Frequently asked questions</H1>
      </PageTitle>
      <Gap />
      <PageContent>
        {loading ? (
          <Loading />
        ) : error ? (
          <pre>{JSON.stringify(error)}</pre>
        ) : (
          <Faq {...data} onChange={onChange} selectedTypeId={type} />
        )}
      </PageContent>
    </section>
  )
}

export default FaqPage
