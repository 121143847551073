import intervalToDuration from "date-fns/intervalToDuration";
import { format, formatDuration } from "date-fns";

export const humanizeDate = (endDate) => {
  const duration = intervalToDuration({
    start: new Date(),
    end: new Date(endDate),
  });

  return {
    remaining: formatDuration(duration, {
      format: ["days", "hours", "minutes"],
      zero: true,
    })
      .replace(/[a-z]*/g, str => str.charAt(0))
      .replace(/(\d)\s(\w)/g, '$1$2'),
    endDay: format(endDate, "EEE, h:mm aa"),
  };
};
