import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import Helmet from "react-helmet";
import { GET_LISTING } from "./queries";
import { Root } from "./styled";
import { Redirect, useParams } from "react-router-dom";
import Loading from "shared/ui/loading";
import Listing from "./listing";
import { ModalManagerContextProvider } from "shared/modal-manager";
import { modalRegistry } from "./modals/index";
import { useFirestore } from "react-redux-firebase";
import { useRef } from "react";
import Page404 from "../404/404";

const ListingContainer = () => {
  const { id } = useParams();
  const { loading, error, data, refetch } = useQuery(GET_LISTING, {
    variables: { id },
    fetchPolicy: "network-only",
  });
  const isFirstSnapshot = useRef(true);
  const firestore = useFirestore();
  useEffect(() => {
    const unsubscribe = firestore
      .collection("listings")
      .doc(id)
      .onSnapshot(
        () => {
          if (isFirstSnapshot.current) {
            isFirstSnapshot.current = false;
          } else {
            // TODO use in memory cache to avoid rerunning query
            refetch();
          }
        },
        (error) => {
          console.log("Listener not set, realtime updates disabled", error);
        }
      );

    return () => unsubscribe();
  }, [refetch]);
  return (
    <Root>
      {loading ? (
        <Loading />
      ) : error || !data ? (
        <pre>{JSON.stringify(error, null, 2)}</pre>
      ) : !data.listing ? (
        <Page404 />
      ) : (
        <>
          <Helmet>
            <title>{data.listing.name}</title>
            {data.listing.seoDescription && <meta name="description" content={data.listing.seoDescription} />}
          </Helmet>
          <ModalManagerContextProvider modalRegistry={modalRegistry}>
            <Listing listing={data.listing} />
          </ModalManagerContextProvider>
        </>
      )}
    </Root>
  );
};

export default ListingContainer;
