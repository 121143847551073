import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { formatCurrency } from "../formatters/currency";
import { colors } from "./colors";

const Table = styled.ul`
  list-style: none;
  padding: 0;
  overflow: auto;
  width: 100%;
  display: table;
  border: 1px solid ${colors.lightGray};
  border-bottom: none;
`;
const Line = styled.li`
  display: table-row;
`;
const Header = styled(Line)`
  font-weight: 700;
`;
const Cell = styled.span`
  border-bottom: 1px solid ${colors.lightGray};
  padding: 12px;
  display: table-cell;
`;

const TableWrapper = styled.div`
  width: 100%;
  overflow: auto;
  padding: 0 24px;
`

export const BidsTable = (props) => {
  const { bids } = props;
  return (
    <TableWrapper>
      <Table>
        <Header>
          <Cell>Value</Cell>
          <Cell>Date placed</Cell>
          <Cell>Status</Cell>
          <Cell>Listing Name</Cell>
          <Cell>Listing Top Bid</Cell>
        </Header>
        {bids.map((bid) => (
          <Line key={bid.id}>
            <Cell>{formatCurrency(bid.value, bid.currency)}</Cell>
            <Cell>{new Date(bid.timestamp).toLocaleDateString()}</Cell>
            <Cell>{bid.status.name}</Cell>
            <Cell>
              <Link target={"_blank"} to={`/listing/${bid.listing.id}`}>
                {bid.listing.name}
              </Link>
            </Cell>
            <Cell>
              {bid.listing.topBid
                ? formatCurrency(
                    bid.listing.topBid.value,
                    bid.listing.topBid.currency
                  )
                : "N/A"}
            </Cell>
          </Line>
        ))}
      </Table>
    </TableWrapper>
  );
};
