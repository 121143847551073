import React from 'react'
import { PageTitle } from 'shared/ui/page-title'
import { H1 } from 'shared/ui/headings'

const Page404 = props => {
  return (
    <PageTitle>
      <H1>404 - We could not find this listing</H1>
    </PageTitle>
  )
}

export default Page404
