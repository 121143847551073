import styled from "styled-components";

const gapSize = 12;

export const Gap = styled.div`
  padding: ${({ x = 1 }) => x * gapSize}px;
  @media screen and (max-width: 768px) {
    padding: ${({ x = 1 }) => x * gapSize / 2}px;
  }
`;
