import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useFirebase } from "react-redux-firebase";
import Grid from "@material-ui/core/Grid";
import { TextField } from "shared/forms";
import { Container } from "@material-ui/core";
import { Button, BUTTON_VARIANTS } from "../../../shared/ui/button";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  confirmPassword: "",
  fbProfile: "",
  phone: "",
};

const SignUpSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Firstname is required"),

  lastName: Yup.string()
    .min(2, "Too Short!")
    .max(50, "Too Long!")
    .required("Lastname is required"),

  phone: Yup.string()
    .required("Phone number is required")
    .matches(/^\d+$/g, "Invalid phone number"),

  email: Yup.string().email().required("Email is required"),

  password: Yup.string()
    .required("Password is required")
    .min(6, "Password is too short - should be 6 chars minimum"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords don't match")
    .required("Confirm Password is required"),
  fbProfile: Yup.string().required("Facebook profile is required"),
});

const Signup = () => {
  const firebase = useFirebase();
  const history = useHistory();
  return (
    <Container maxWidth="sm">
      <Helmet>
        <title>Sign up</title>
      </Helmet>
      <h1>Create account</h1>
      <Formik
        initialValues={initialState}
        validationSchema={SignUpSchema}
        onSubmit={(values, { setSubmitting, setFieldError, resetForm }) => {
          firebase
            .auth()
            .createUserWithEmailAndPassword(values.email, values.password)
            .then((credential) => {
              //credential.user.sendEmailVerification();
              firebase.updateProfile(
                {
                  displayName: `${values.firstName} ${values.lastName}`,
                  email: values.email,
                  fbProfile: values.fbProfile,
                  phone: values.phone,
                },
                {
                  useSet: true,
                  merge: true,
                }
              );
              setSubmitting(false);
              resetForm();
              history.go(0);
            })
            .catch((e) => {
              if (e.code === "auth/email-already-in-use") {
                setFieldError("email", "This email is already in use.");
              }
              setSubmitting(false);
            });
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField name="firstName" type="text" label="First Name" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField name="lastName" type="text" label="Last Name" />
              </Grid>
              <Grid item xs={12}>
                <TextField name="email" type="email" label="Email" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField name="password" type="password" label="Password" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="confirmPassword"
                  type="password"
                  label="Confirm Password"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  name="fbProfile"
                  type="text"
                  label="Facebook profile"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField name="phone" type="text" label="Phone number" />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={isSubmitting}
                  type="submit"
                  variant={BUTTON_VARIANTS.ACCENT}
                >
                  Create account
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default Signup;
