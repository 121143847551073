import React, { useState, useEffect } from "react";
import { ListItem } from "shared/ui/plain-list";
import {
  StyledHeader,
  Logo,
  LogoMobile,
  GlobalStyle,
  LogoHolder,
  LinkList,
  StyledNavLink,
} from "./styled";
import { routes } from "shared/routes";
import UserBox from "./user-box";
import { Link } from "react-router-dom";

const Header = (props) => {
  const [shrink, setShrink] = useState(false);

  const checkScrollTop = () => {
    if (window.pageYOffset > 400) {
      setShrink(true);
    } else if (window.pageYOffset <= 400) {
      setShrink(false);
    }
  };

  useEffect(() => {
    checkScrollTop();
    window.addEventListener("scroll", checkScrollTop);
    return () => window.removeEventListener("scroll", checkScrollTop);
  }, []);

  return (
    <StyledHeader $shrink={shrink}>
      <GlobalStyle />
      <LogoHolder>
        <Link to={"/"}>
          <Logo src={"/images/logo_primary.png"} />
          <LogoMobile src={"/images/logo_short.png"} />
        </Link>
      </LogoHolder>
      <nav>
        <LinkList>
          {routes
            .filter(({ navBar }) => navBar)
            .map((route) => (
              <ListItem key={route.path}>
                <StyledNavLink to={route.path} exact={route.exact}>
                  {route.name}
                </StyledNavLink>
              </ListItem>
            ))}
        </LinkList>
      </nav>
      <UserBox />
    </StyledHeader>
  );
};

export default Header;
