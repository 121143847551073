import styled from "styled-components";

const Icon = styled.i`
  &:before {
    font-family: "iconsmind";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    color: ${({ $color }) => ($color ? $color : "inherit")};
    font-size: ${({ $size }) => ($size ? $size : "16")}px;
    content: "\\e${(props) => props.code}";
  }
`;

export default Icon;
