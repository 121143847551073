import React from 'react'
import { P, SmallP } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";
import { useListing } from '../context';
import styled from 'styled-components';

const JustifiedSmallP = styled(SmallP)`
  text-align: justify;
  text-justify: inter-word;
  margin-right: 20px;
`

const About = () => {
  const { description } = useListing()
  return <>
    <P>
      <b>About this listing</b>
    </P>
    <Gap x={0.5} />
    <JustifiedSmallP>{description}</JustifiedSmallP>
    <Gap x={2} />
  </>
}

export default About