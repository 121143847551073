import React from "react";
import { formatCurrency } from "shared/formatters/currency";
import { H2, H6, P } from "shared/ui/headings";
import Modal from "shared/ui/modal";
import { Gap } from "shared/ui/gap";
import { ModalBody, ModalContent, ModalHeader } from "../styled";
import { Separator } from "shared/ui/separator";
import Countdown from "../../bid-banner/countdown";
import { FakeButton, ButtonList, BUTTON_VARIANTS } from "shared/ui/button";
import { Center } from "shared/ui/center";
import { humanizeDate } from "../../bid-banner/helper";
import { Detail, KeyDetails } from "../../header/styled";
import { Link } from "react-router-dom";

const BidConfirmedModal = (props) => {
  const { modalProps, value, currency, endDate } = props;
  const { remaining, endDay } = humanizeDate(endDate);

  return (
    <Modal {...modalProps}>
      <ModalContent>
        <ModalHeader>
          <H2>
            <b>You just bid on an auction!</b>
          </H2>
          <Gap x={0.5} />
          <P>Yours is the highest bid at the moment</P>
          <Separator />
          <Gap />
          <KeyDetails>
            <Detail title="Your bid">{formatCurrency(value, currency)}</Detail>
            <Detail title="Time left">{`${remaining} ${endDay}`}</Detail>
          </KeyDetails>
        </ModalHeader>
        <ModalBody>
          <P>
            We know, bidding on stuff is so much fun. Even if our users aren’t
            successful on every auction, they keep coming back form more.
            <br />
            Determination is key!
          </P>
          <Gap />
          <ButtonList>
            <Link to="/my">
              <FakeButton $noWrap variant={BUTTON_VARIANTS.SECONDARY}>
                View your bids
              </FakeButton>
            </Link>
            {/*<Gap />
            <Link to="/my/listings">
              <FakeButton $noWrap variant={BUTTON_VARIANTS.PRIMARY}>
                View other listings
              </FakeButton>
            </Link>*/}
          </ButtonList>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default BidConfirmedModal;
