import React from "react";
import { formatCurrency } from "shared/formatters/currency";
import ImageLoader from "shared/ui/image-loader";
import { useListing } from "../context";
import {
  Root,
  Content,
  Title,
  KeyDetails,
  Detail,
  ImageWrapper,
} from "./styled";

export const ListingHeader = () => {
  const { name, type, monthlyProfit, ableToSellAds, image, dataCurrency, currency } = useListing();
  const actualCurrency = dataCurrency || currency

  return (
    <Root>
      <Content>
        <Title>{name}</Title>
        <KeyDetails $fullWidth>
          <Detail title="Platform">{type.name}</Detail>
          <Detail $hideMobile title="Monthly profit">
            {formatCurrency(monthlyProfit, actualCurrency)}/mo
          </Detail>
          <Detail $hideMobile title="Able to sell ads">
            {ableToSellAds ? "Yes" : "No"}
          </Detail>
        </KeyDetails>
      </Content>
      <ImageWrapper>
        <div>
          <ImageLoader cover source={image?.src} />
        </div>
      </ImageWrapper>
    </Root>
  );
};
