import React from "react";
import { default as MuiTextField } from "@material-ui/core/TextField";
import { useField } from "formik";

export const TextField = (props) => {
  const [field, meta] = useField(props);
  let extraProps = {};
  const { validateOnChange, ...inputProps } = props;
  if ((validateOnChange || meta.touched) && meta.error) {
    extraProps = {
      error: true,
      helperText: meta.error,
    };
  }

  return <MuiTextField fullWidth {...field} {...inputProps} {...extraProps} />;
};
