import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useFirebase } from "react-redux-firebase";
import { useHistory } from "react-router-dom";
import { useCurrentUser } from "../../../shared/hooks/use-current-user";
// import MyListings from "./listings";
import { H1 } from "shared/ui/headings";
import { Grid, Cell } from "shared/ui/grid";
import { Gap } from "shared/ui/gap";
import { Root, TabHeaders, TabHeader, TabContainer } from "./styled";
import SellerInfo from "../../../shared/ui/seller-info";
import { Container } from "shared/ui/container";
import MyBids from "./bids";

export const My = () => {
  const { auth, profile } = useCurrentUser();
  const firebase = useFirebase();
  const history = useHistory();
  useEffect(() => {
    if (!auth?.uid) {
      history.push("/");
    }
  }, [auth]);

  const tabs = [
    /*{
      name: "My listings",
      component: MyListings,
    },*/
    {
      name: "My bid history",
      component: MyBids,
    },
  ];

  const [activeTab, setActiveTab] = useState(0);

  const ActiveTabComponent = tabs[activeTab].component;
  return (
    <Root>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Container>
        <Grid $align="flex-start">
          <Cell>
            <H1>Dashboard</H1>
          </Cell>
        </Grid>
        <Gap x={2} />
        <Grid $breakAt="768" $align="flex-start">
          <Cell $s={9}>
            <TabContainer>
              <TabHeaders>
                {tabs.map((tab, idx) => (
                  <TabHeader
                    $isActive={idx === activeTab}
                    key={tab.name}
                    onClick={() => setActiveTab(idx)}
                  >
                    {tab.name}
                  </TabHeader>
                ))}
              </TabHeaders>
              <ActiveTabComponent />
            </TabContainer>
          </Cell>
          <Cell $s={3}>
            <SellerInfo hideHeader seller={profile} />
            <Gap />
            <button onClick={firebase?.logout}>Logout</button>
          </Cell>
        </Grid>
      </Container>
    </Root>
  );
};
