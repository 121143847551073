import React from 'react'
import Graph from "../graphs";
import { Gap } from "shared/ui/gap";
import { useListing } from '../context';

const Graphics = () => {
  const { graphics, dataCurrency, currency } = useListing()
  const actualCurrency = dataCurrency || currency

  return <>
    <div>
      {graphics.map((graphic) => (
        <Graph key={graphic.name} graphic={graphic} currency={actualCurrency} />
      ))}
    </div>
    <Gap x={2} />
  </>
}

export default Graphics