import styled from "styled-components";
import Icon from "./create-icon";

export const Diploma = styled(Icon).attrs({ code: "80f" })``;
export const Like = styled(Icon).attrs({ code: "a43" })``;
export const Shield = styled(Icon).attrs({ code: "c4f" })``;
export const Trophy = styled(Icon).attrs({ code: "d7f" })``;
export const Clock = styled(Icon).attrs({ code: "76f" })``;
export const DuplicateWindow = styled(Icon).attrs({ code: "841" })``;
export const LifeJacket = styled(Icon).attrs({ code: "a3d" })``;
