import styled from "styled-components";
import { colors } from "../colors";

export const SellerCard = styled.div`
  color: black;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  min-height: 130px;
  position: relative;
  overflow: hidden;
`;

export const SellerImage = styled.img`
  position: absolute;
  top: 15px;
  left: 0;
  width: 60px;
  border-radius: 50%;
  border: 1px solid ${colors.midLightGray};
`;

export const SellerName = styled.span`
  padding: 15px 15px 15px 75px;
  font-weight: 700;
  display: flex;
  align-items: center;
  min-height: 90px;
`;

export const SellerDescription = styled.div`
  padding: 15px 0;
  width: 100%;
`;
