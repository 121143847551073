import { gql } from "@apollo/client";

export const GET_LISTINGS = gql`
  {
    listings {
      id
      name
      description
      type {
        id
        name
      }
      owner {
        displayName
      }
      topBid {
        value
        currency
      }
    }
    listingTypes {
      id
      name
    }
  }
`;
