import React, { useEffect } from "react";
import GlobalStyles from "../globalStyles";
import { Switch, Route } from "react-router-dom";
import Header from "./partials/header";
import Helmet from "react-helmet";
import BackToTop from "shared/ui/back-to-top";
import { routes } from "shared/routes";
import Footer from "./partials/footer";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { useAuthChange } from "../shared/hooks/use-auth-change";
import { SnackbarProvider } from "notistack";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "../shared/ui/error-fallback";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#70b1ae",
      main: "#40817f",
      dark: "#075453",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffff54",
      main: "#ffda03",
      dark: "#c7a900",
      contrastText: "#000",
    },
  },
});

const App = () => {
  useEffect(() => {
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);
  useAuthChange();
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <GlobalStyles />
        <Helmet titleTemplate="Flipstorm - %s">
          <html lang="en" />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <title>Buy and Sell T-Shirt accounts</title>
          <meta name="description" content="Buy and Sell T-Shirt accounts" />
          <link
            href="https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap"
            rel="stylesheet"
          />
          <link
            rel="stylesheet"
            href="//fonts.googleapis.com/css?family=Open+Sans%3A200%2C300%2C400%2C400i%2C500%2C600%2C700%7CMerriweather%3A300%2C300i%7CMaterial+Icons"
            type="text/css"
            media="all"
          />
        </Helmet>
        <Header />
        <Switch>
          {routes.filter(route => !route.disabled).map((route) => (
            <Route key={route.path} {...route} />
          ))}
        </Switch>
        <BackToTop />
        <Footer />
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
