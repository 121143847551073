import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { configureStore } from "../store";
// Create store with reducers and initial state

const fbConfig = {
  apiKey: "AIzaSyA1krYqN8C7kbEcImD5pSmk459k4ZD6ths",
  authDomain: "flipstorm-5baa9.firebaseapp.com",
  databaseURL: "https://flipstorm-5baa9.firebaseio.com",
  projectId: "flipstorm-5baa9",
  storageBucket: "flipstorm-5baa9.appspot.com",
  messagingSenderId: "896461759791",
  appId: "1:896461759791:web:8361cefe7736fa75b92dda",
  measurementId: "G-N0VV71RR24",
};
firebase.initializeApp(fbConfig);
firebase.firestore();

const preloadedState = window.__PRELOADED_STATE__;

delete window.__PRELOADED_STATE__;

const configuredStore = configureStore(firebase, preloadedState);
export const store = configuredStore.store;
export const rrfProps = configuredStore.rrfProps;
