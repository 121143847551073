import React from "react";
import { H6, P } from "shared/ui/headings";
import Modal from "shared/ui/modal";
import { ModalBody, ModalContent, ModalHeader } from "../styled";

const WhatHappensModal = (props) => {
  const { modalProps } = props;

  return (
    <Modal {...modalProps}>
      <ModalContent>
        <ModalHeader>
          <H6>
            <b>What happens after a bid?</b>
          </H6>
        </ModalHeader>
        <ModalBody>
          <P>
            Leverage agile frameworks to provide a robust synopsis for high
            level overviews. Iterative approaches to corporate strategy foster
            collaborative thinking to further the overall value proposition.
            Organically grow the holistic world view of disruptive innovation
            via workplace diversity and empowerment.
            <br />
            <br />
            Bring to the table win-win survival strategies to ensure proactive
            domination. At the end of the day, going forward, a new normal that
            has evolved from generation X is on the runway heading towards a
            streamlined cloud solution. User generated content in real-time will
            have multiple touchpoints for offshoring.
          </P>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default WhatHappensModal;
