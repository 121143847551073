import { gql } from "@apollo/client";

export const GET_FAQS = gql`
  {
    faqs {
      id
      title
      body
      type {
        id
      }
    }
    faqTypes {
      id
      name
    }
  }
`;
