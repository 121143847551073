import React from "react";
import { useTransition, animated } from "react-spring";
//import { useMeasure } from "react-use";

const Collapsible = (props) => {
  const { isOpen, children } = props
  //const [ref, { height }] = useMeasure()
  const transitions = useTransition(isOpen, null, {
    from: { maxHeight: "0px", opacity: 0, overflow: "hidden" },
    enter: { maxHeight: "1000px", opacity: 1 },
    leave: { maxHeight: "0px", opacity: 0 },
  })

  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div key={key} style={props}>
          <div >
            {children}
          </div>
        </animated.div>
      )
  );
};
export default Collapsible;
