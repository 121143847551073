import React from 'react'
import { P, SmallP } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";
import { useListing } from '../context';

const Niches = () => {
  const { niches } = useListing()
  return <>
    <P>
      <b>Main niches</b>
    </P>
    <Gap x={0.5} />
    <SmallP>{niches.join(", ")}</SmallP>
    <Gap x={2} />
  </>
}

export default Niches