import React, { useState } from "react";
import { animated, useTransition } from "react-spring";
import { Gap } from "../gap";
import { TabPanel, Tab, TabWrapper } from './styled'

const Tabs = ({ items, currentTab, onChange }) => {
  const [previousTab, setPreviousTab] = useState(currentTab);

  const trans = useTransition(items[currentTab], p => p.key, {
    unique: true,
    from: () => {
      return {
        transform: `translate3d(${(currentTab - previousTab) * 100}%,0,0)`,
        position: "static"
      };
    },
    enter: {
      transform: "translate3d(0%,0,0)",
      position: "static"
    },
    leave: () => ({
      transform: `translate3d(${(previousTab - currentTab) * 100}%,0,0)`,
      zIndex: 1,
      position: "absolute",
    })
  });
  if (currentTab !== previousTab) setPreviousTab(currentTab);

  return (
    <>
      <TabWrapper>
        {items.map((item, tabIndex) => (
          <Tab
            key={tabIndex}
            onClick={() => onChange(tabIndex)}
            selected={tabIndex === currentTab}
          >
            {item.label}
          </Tab>
        ))}
      </TabWrapper>
      <Gap />
      <TabPanel>
        {trans.map(({ item, props, key }) => (
          <animated.div
            key={key}
            style={{
              ...props,
              width: "100%",
            }}
          >
            {item.component}
          </animated.div>
        ))}
      </TabPanel>
    </>
  );
};

export default Tabs;
