import styled, { css } from "styled-components";
import { colors } from "./colors";

export const BUTTON_VARIANTS = {
  PRIMARY: "primary",
  ACCENT: "accent",
  SECONDARY: "secondary",
};

const buildStyles = (bgColor, fgColor, borderColor = bgColor) => `
	background-color: ${bgColor};
	color: ${fgColor};
  border: 1px solid ${borderColor};
`;

const stylesForVariant = ({ variant }) => {
  switch (variant) {
    case BUTTON_VARIANTS.ACCENT: {
      return buildStyles(colors.accent, colors.black);
    }
    default:
    case BUTTON_VARIANTS.PRIMARY: {
      return buildStyles(colors.primary, colors.white);
    }
    case BUTTON_VARIANTS.SECONDARY: {
      return buildStyles(colors.white, colors.primary, "rgba(0, 0, 0, 0.38)");
    }
  }
};

export const PlainButton = styled.button`
  all: unset;
`;

export const InlineButton = styled(PlainButton)`
  font-weight: 700;
  color: ${colors.primary};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 14px;
  padding-top: 6px;
  &:first-child {
    padding-right: 12px;
  }
`;

const buttonStyles = css`
  box-sizing: border-box;
  border: none;
  ${stylesForVariant}
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2);
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
  border-radius: 4px;
  padding: 9px 30px;
  text-decoration: none;
  transition: all 0.2s ease-out;
  cursor: pointer;
  &:hover,
  a:hover & {
    opacity: 0.9;
    transform: translateY(-4px);
  }

  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 16px;
    padding: 6px 20px;
  }

  ${({ $noWrap }) =>
    $noWrap
      ? css`
          white-space: nowrap;
        `
      : ""}
  ${({ $wide }) =>
    $wide
      ? css`
          width: 100%;
          text-align: center;
        `
      : ""}
`;

export const FakeButton = styled.div`
  ${buttonStyles}
`;

export const Button = styled(PlainButton)`
  ${buttonStyles}
`;

export const PlainLink = styled.a`
  text-decoration: none;
  color: ${colors.primary};
  &:hover {
    text-decoration: underline;
    text-underline-position: under;
  }
`;

export const ButtonList = styled.div`
  display: flex;
  align-items: center;
`;
