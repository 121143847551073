import styled from "styled-components";

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const OrderedList = styled(List).attrs({ as: "ol" });

export const ListItem = styled.li``;
