import Homepage from "../app/pages/homepage";
import Contacts from "../app/pages/contacts";
import MarketPlace from "../app/pages/marketplace";
import Listing from "../app/pages/listing";
import Signup from "../app/pages/signup";
import Login from "../app/pages/login";
import { My } from "../app/pages/my";
import FaqPage from "../app/pages/faq";
import Terms from "../app/pages/terms";

export const routes = [
  {
    path: "/",
    exact: true,
    component: Homepage,
    navBar: false,
    name: "Home",
  },
  {
    disabled: true,
    path: "/marketplace",
    exact: true,
    component: MarketPlace,
    navBar: false,
    name: "Marketplace",
  },
  {
    path: "/listing/:id",
    exact: true,
    component: Listing,
    navBar: false,
    name: "Listing",
  },
  {
    disabled: true,
    path: "/contacts",
    exact: true,
    component: Contacts,
    navBar: false,
    name: "Contacts",
  },
  {
    disabled: true,
    path: "/signup",
    exact: true,
    component: Signup,
    navBar: false,
    name: "Signup",
  },
  {
    path: "/login",
    exact: true,
    component: Login,
    navBar: false,
    name: "Login",
  },
  {
    path: "/my",
    exact: true,
    component: My,
    navBar: false,
    name: "My",
  },
  {
    path: "/faq/:type?",
    exact: true,
    component: FaqPage,
    navBar: false,
    name: "FAQ",
  },
  {
    path: "/terms",
    exact: true,
    component: Terms,
    navBar: false,
    name: "Terms",
  },
];
