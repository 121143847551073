import styled from "styled-components";
import { PlainButton } from "../../../shared/ui/button";
import { colors } from "../../../shared/ui/colors";

export const Root = styled.main`
  background: ${colors.lightGray};
`;

export const TabContainer = styled.section`
  position: relative;
  background: ${colors.white};
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.15);
`;

export const TabHeaders = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  padding: 15px 24px;
`;

export const TabHeader = styled(PlainButton)`
  cursor: pointer;
  padding: 15px;
  border-bottom: 2px solid
    ${({ $isActive }) => ($isActive ? colors.primary : colors.lightGray)};
`;
