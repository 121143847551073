import React from "react";
import { Grid, Cell } from "shared/ui/grid";
import { Container } from "shared/ui/container";
import { Gap } from "shared/ui/gap";
import { ListingContext } from './context'
import BidBanner from "./bid-banner";
import BiddingInfo from "./bid-banner/bidding-info";
import { ListingHeader } from "./header";
import ListingBody from "./body";
import { useBreakpoints } from "shared/hooks/use-breakpoints";

const alternateViewBreakpoints = ['xs', 's', 'm']

const Listing = (props) => {
  const { listing } = props;
  const { currentViewport } = useBreakpoints()
  const isAlternateView = alternateViewBreakpoints.includes(currentViewport)
  if (!listing) return null;
  return (
    <ListingContext.Provider value={listing}>
      <ListingHeader />
      <Gap x={2} />
      <Container>
        <Grid $noPadding $align="flex-start" $breakAt={1080}>
          <Cell $s={8} $visibleOverflow>
            <ListingBody>
              {isAlternateView && <>
                <BiddingInfo />
                <Gap x={2} />
              </>}
            </ListingBody>
          </Cell>
          <Cell $s={4}>
            <BidBanner />
            {!isAlternateView && <BiddingInfo />}
          </Cell>
        </Grid>
      </Container>
    </ListingContext.Provider>
  );
};

export default Listing;
