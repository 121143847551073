import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Chart from "chart.js";
import "chartjs-plugin-deferred";

export const options = {
  lineTension: 0,
  fill: false,
  pointRadius: 4,
  borderWidth: 2,
  borderColor: "#e6e6e6",
  pointBorderWidth: 0,
  pointHitRadius: 4,
};

const Root = styled.div`
  width: calc(100% + 24px);
  position: relative;
  overflow: scroll;
  margin: 0 -12px;
`;
  
const Wrapper = styled.div`
  padding: 0 12px;
  height: 250px;
  position: relative;
  min-width: 600px;
  overflow: hidden;
`;
// add config to render ticks properly and extra xAxis for line values

const Graph = (props) => {
  let chartInstance = null;
  const chartRef = useRef(null);
  useEffect(() => {
    if (!chartInstance) {
      const ctx = chartRef.current.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 200);
      gradient.addColorStop(1, "rgba(64,129,127, 0)");
      gradient.addColorStop(0, "rgba(64,129,127,0.5)");

      chartInstance = new Chart(ctx, {
        type: "line",
        data: {
          labels: props.labels,
          datasets: props.datasets.map((ds) => ({
            ...ds,
            backgroundColor: gradient,
          })),
        },
        options: {
          ...options,
          layout: {
            padding: {
              top: 10,
            },
          },
          responsive: true,
          maintainAspectRatio: false,
          title: {
            display: false,
            fontSize: 14,
            fontColor: "#808080",
            text: props.title,
            position: "top",
          },
          tooltips: {
            mode: "x",
            position: "nearest",
          },
          legend: {
            display: false,
            position: "top",
            onClick: () => {},
            labels: {
              usePointStyle: true,
              boxWidth: 20,
              fontColor: "#000000",
              fontSize: 1,
            },
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  drawOnChartArea: true,
                  display: true,
                  drawBorder: false,
                },
                ticks: {
                  fontSize: 14,
                  fontColor: "#000000",
                  fontFamily: '"DM Sans", "Open Sans", Helvetica, Arial, sans-serif',
                  // fontStyle: "bold",
                  padding: 10,
                },
              },
            ],
            yAxes: [
              {
                gridLines: {
                  display: false,
                  drawBorder: false,
                },
                ticks: {
                  display: false,
                  autoSkip: true,
                  padding: 30,
                  fontSize: 12,
                  fontColor: "#000000",
                  callback: (value, index, values) => {
                    return value.toFixed(0);
                  },
                },
              },
            ],
          },
          plugins: {
            legend: {
              labels: {
                font: {
                  family: '"DM Sans", "Open Sans", Helvetica, Arial, sans-serif'
                }
              }
            },
            deferred: {
              yOffset: "100%",
              delay: 500,
            },
          },
        },
      });
    }
  });
  return (
    <Root>
      <Wrapper>
        <canvas ref={chartRef}></canvas>
      </Wrapper>
    </Root>
  );
};
export default Graph;
