import React from "react";
import { Grid, Cell } from "shared/ui/grid";
import {
  SellerCard,
  SellerImage,
  SellerName,
  SellerDescription,
} from "./styled";
import { P } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";

const SellerInfo = (props) => {
  const { bio, image, displayName } = props.seller;
  return (
    <SellerCard>
      <SellerImage {...image} />
      <SellerName>{displayName}</SellerName>
      <SellerDescription>{bio}</SellerDescription>
    </SellerCard>
  );
};

export default SellerInfo;
