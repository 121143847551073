import styled from "styled-components";
import { colors } from "shared/ui/colors";
import { SmallP } from "shared/ui/headings";
import { propOr } from "ramda";

export const Root = styled.div`
  padding: 2px;
  @media screen and (max-width: 1080px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    padding: 0;
  }
`;

export const Banner = styled.div`
  width: 100%;
  box-shadow: 0px 0px 3px 2px rgba(0, 0, 0, 0.15);
  background: white;
  border-radius: 5px;
  overflow: hidden;
  @media screen and (max-width: 1080px) {
    border-radius: 0;
  }
`;

export const BidHeader = styled.div`
  background: ${colors.primaryLight};
  padding: 20px;
  text-align: center;
  @media screen and (max-width: 1080px) {
    padding: 10px;
  }
`;

export const BidLine = styled.div`
  display: flex;
  padding: 18px;
  align-items: ${propOr("flex-start", "$align")};
  justify-content: space-between;
`;

export const OrLine = styled.div`
  text-align: center;
  margin: 0 18px;
  position: relative;
  z-index: 0;

  &:before {
    content: "";
    z-index: -1;
    height: 2px;
    width: 100%;
    top: calc(50% - 1px);
    position: absolute;
    background: ${colors.lightGray};
    left: 0px;
  }
  > ${SmallP} {
    background: ${colors.white};
    padding: 0 10px;
    display: inline-block;
  }
`;

export const EndDate = styled.span`
  padding-left: 5px;
  color: ${colors.midGray};
`;

export const CountdownRoot = styled.div`
  border-top: 2px solid ${colors.lightGray};
  padding: 5px;
  text-align: center;
`;

export const UsefulLinks = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;
