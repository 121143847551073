import React from "react";
import { useQuery } from "@apollo/client";
import { Grid, Cell } from "shared/ui/grid";
import Loading from "shared/ui/loading";
import { GET_OWN_BIDS } from "./queries";
import { Root } from "./styled";
import { BidsTable } from "shared/ui/bids-table";

const MyBids = (props) => {
  const { loading, error, data } = useQuery(GET_OWN_BIDS);

  return (
    <Root>
      {loading ? (
        <Loading />
      ) : error ? (
        <pre>{JSON.stringify(error, null, 2)}</pre>
      ) : (
        <BidsTable bids={data.myBids} />
      )}
    </Root>
  );
};

export default MyBids;
