import React from "react";
import { hydrate } from "react-dom";
import App from "./app";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { store, rrfProps } from "./app/store";
import { apolloClient } from "./app/graphql";
import { ApolloProvider } from "@apollo/client";

hydrate(
  <BrowserRouter>
    <Provider store={store}>
      <ReactReduxFirebaseProvider {...rrfProps}>
        <ApolloProvider client={apolloClient}>
          <App />
        </ApolloProvider>
      </ReactReduxFirebaseProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
