import React from "react";
import {
  Banner,
  BidHeader,
  BidLine,
  Root,
  OrLine,
} from "./styled";
import { P, SmallP, H5 } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";
import { formatCurrency, getCurrencySymbol } from "shared/formatters/currency";
import { MIN_BID_INCREMENT } from "shared/constants";
import { Button, BUTTON_VARIANTS } from "shared/ui/button";
import { Detail } from "../header/styled";
import { TextField } from "shared/forms";
import { withModalManagement } from "shared/modal-manager";
import InputAdornment from "@material-ui/core/InputAdornment";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Countdown from "./countdown";
import { LISTING_MODALS } from "../modals/constants";
import { useCurrentUser } from "shared/hooks/use-current-user";
import { useHistory } from "react-router-dom";
import { Center } from "shared/ui/center";
import { useListing } from '../context';
import { isNil } from "ramda";

const BidBanner = (props) => {
  const {
    minimumBidAmount,
    minimumBidIncrement,
    topBid,
    sellPrice,
    currency,
    id,
    endDate,
  } = useListing();
  const { modalActions } = props
  const currentUser = useCurrentUser();
  const history = useHistory();
  const increment = minimumBidIncrement || MIN_BID_INCREMENT;
  const minBid = (topBid ? topBid.value : minimumBidAmount) + increment;
  const hasSellPrice = !isNil(sellPrice) && sellPrice !== 0;
  const isTopBidOwnedByCurrentUser =
    topBid && topBid.owner.id === currentUser.auth?.uid;
  const hasEnded = new Date(endDate).getTime() < new Date().getTime();

  return (
    <Root>
      <Banner>
        <BidHeader>
          <SmallP>Current bid</SmallP>
          <H5>
            <b>
              {topBid
                ? formatCurrency(topBid.value, topBid.currency)
                : formatCurrency(minimumBidAmount, currency)}
            </b>
          </H5>
        </BidHeader>
        {hasEnded && (
          <>
            <Gap />
            <Center>
              <P>This listing has ended</P>
            </Center>
            <Gap />
          </>
        )}
        {isTopBidOwnedByCurrentUser && !hasEnded && (
          <>
            <Gap />
            <Center>
              <P>You currently are the top bidder</P>
            </Center>
            <Gap />
          </>
        )}
        {!hasEnded && (
          <>
            <Formik
              initialValues={{
                bid: minBid,
              }}
              validateOnChange
              validationSchema={Yup.object().shape({
                bid: Yup.number()
                  .min(minBid, "Value needs to be at least ${min}")
                  .required("Required"),
              })}
              onSubmit={(values, actions) => {
                if (!currentUser.auth?.isEmpty) {
                  modalActions.triggerModal(LISTING_MODALS.bidConfirmation, {
                    currency: currency,
                    listingId: id,
                    value: parseFloat(values.bid),
                    endDate,
                  });
                  actions.setSubmitting(false);
                } else {
                  history.push("/login");
                }
              }}
            >
              {({ isSubmitting, isValid }) => (
                <Form>
                  <BidLine>
                    <TextField
                      validateOnChange
                      name="bid"
                      type="text"
                      size="small"
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            {getCurrencySymbol(currency)}
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Gap x={0.5} />
                    <Button
                      $noWrap
                      type="submit"
                      disabled={isSubmitting || !isValid}
                      variant={
                        hasSellPrice
                          ? BUTTON_VARIANTS.SECONDARY
                          : BUTTON_VARIANTS.PRIMARY
                      }
                    >
                      Place bid
                    </Button>
                  </BidLine>
                </Form>
              )}
            </Formik>
            {hasSellPrice && (
              <>
                <OrLine>
                  <SmallP>OR</SmallP>
                </OrLine>
                <BidLine $align={"flex-end"}>
                  <Detail $compact title="Sell price">
                    {formatCurrency(sellPrice, currency)}
                  </Detail>
                  <Gap x={0.5} />
                  <Button $noWrap variant={BUTTON_VARIANTS.PRIMARY}>
                    Buy now
                  </Button>
                </BidLine>
              </>
            )}
          </>
        )}
        {!hasEnded && <Countdown endDate={endDate} />}
      </Banner>
    </Root>
  );
};

export default withModalManagement(BidBanner);
