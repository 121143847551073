import React from "react";
import { Grid } from "shared/ui/grid";
import { Gap } from "shared/ui/gap";
import ThumbUpOutlinedIcon from "@material-ui/icons/ThumbUpOutlined";
import { P } from "shared/ui/headings";

const VerifiedBadge = (props) => {
  if (!props.show) return null;

  return (
    <Grid>
      <ThumbUpOutlinedIcon />
      <Gap x={0.5} />
      <div>All info on this listing was verified by Flipstorm</div>
    </Grid>
  );
};

export default VerifiedBadge;
