export const colors = {
  primary: "#40817f",
  primaryDark: "rgb(0, 125, 121)",
  primaryLight: "rgb(205, 229, 228)",
  secondary: "#88d8d5",
  accent: "#FFDA03",
  white: "#FFFFFF",
  black: "#000000",
  midGray: "#808080",
  midLightGray: "rgb(180, 180, 180)",
  lightGray: "rgb(235, 235, 235)",
  transparent: 'transparent'
};
