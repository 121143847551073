import React from "react";
import Graph, { options } from "shared/ui/graph";
import { colors } from "shared/ui/colors";
import { P } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";

const ListingGraph = (props) => {
  const { graphic } = props;
  let lineChartData = null;
  if (graphic && graphic.list.length > 0) {
    lineChartData = {
      labels: graphic.list.map((rec) => [
        rec.label,
        parseFloat(rec.value).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      ]),
      datasets: [
        Object.assign({
          ...options,
          fill: "origin",
          pointBackgroundColor: colors.white,
          pointBorderColor: colors.primary,
          pointRadius: 6,
          pointBorderWidth: 3,
          borderWidth: 3,
          borderColor: colors.primary,
          label: graphic.name,
          data: graphic.list.map((val) => parseFloat(val.value)),
          spanGaps: true,
        }),
      ],
    };
  }
  return (
    lineChartData && (
      <>
        <P $inheritColor>
          <b>{graphic.name}</b>
        </P>
        <Gap />
        <Graph {...lineChartData} />
        <Gap x={2} />
      </>
    )
  );
};
export default ListingGraph;
