import React from "react";
import Helmet from "react-helmet";
import Typed from "react-typed";

import {
  Hero,
  HeroImage,
  Logo,
  PageSection,
  AccentText,
  FeatureList,
  FeatureItem,
} from "./styled";
import { H1, H2, P, SmallP } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";
import { Center } from "shared/ui/center";
import { Button, BUTTON_VARIANTS } from "shared/ui/button";
import { colors } from "shared/ui/colors";
import { Grid, Cell } from "shared/ui/grid";
import { logos, featuresOne, featuresTwo } from "./constants";
import { Germany } from "shared/ui/flags";
import { useBreakpoints } from "shared/hooks/use-breakpoints";
import { equals } from "ramda";

const Homepage = (props) => {
  const { currentViewport } = useBreakpoints()
  const isMobile = ['xs', 's'].find(equals(currentViewport)) !== undefined
  return (
    <div>
      <Hero>
        <HeroImage src={"/images/logo.png"} />
        <Gap />
        <H1>Buy or sell a print-on-demand account at Flipstorm</H1>
        <Gap />
        <a target="_blank" href="https://www.facebook.com/groups/flipstorm">
          <Button variant={BUTTON_VARIANTS.ACCENT}>To buy</Button>
        </a>
        <Gap />
        <a target="_blank" href="https://www.facebook.com/groups/flipstorm">
          <Button variant={BUTTON_VARIANTS.ACCENT}>To sell</Button>
        </a>
      </Hero>
      <PageSection>
        <Grid $wrap>
          {logos.map((featured) => (
            <Cell key={featured.url} $s={isMobile ? 6 : 4}>
              <a href={featured.url} rel="noreferrer" target="_blank">
                <Logo src={featured.img} />
              </a>
            </Cell>
          ))}
        </Grid>
      </PageSection>
      <PageSection>
        <Center>
          <H2>
            <b>Buy</b>
            {` an account `}
            <AccentText>
              <Typed
                strings={[
                  "to invest.",
                  "to expand.",
                  "to scale.",
                  "to save time.",
                ]}
                typeSpeed={120}
                backSpeed={50}
                loop
                smartBackspace={false}
              />
            </AccentText>
          </H2>
        </Center>
      </PageSection>
      <PageSection>
        <Grid $breakAt={768}>
          <Cell $s={6}>
            <H2>
              Why should you <b>buy</b> an account?
            </H2>
            <Gap />
            <P>
              Establishing a successful Print-on-Demand (POD) account takes
              time, work and often money. However, investors are not looking for
              work, but for opportunities.
            </P>
            <Gap />
            <P>
              Investing in an existing POD business with ongoing sales can make
              this entry much easier and often saves you the long, rocky road to
              regular and reliable sales.
            </P>
          </Cell>
          <Cell $s={6}>
            <FeatureList>
              {featuresOne.map(({ Component, title, description }) => (
                <FeatureItem key={title}>
                  <Component $size={42} $color={colors.primary} />
                  <div>
                    <SmallP $inheritColor>
                      <b>{title}</b>
                    </SmallP>
                    <Gap x={0.5} />
                    <SmallP>{description}</SmallP>
                  </div>
                </FeatureItem>
              ))}
            </FeatureList>
          </Cell>
        </Grid>
      </PageSection>
      <Gap x={2} />
      <Hero>
        <H2>
          Do you want to <b>buy</b> an Account?
          <br />
          Then register!
        </H2>
        <Gap />
        <a target="_blank" href="https://www.facebook.com/groups/flipstorm">
          <Button variant={BUTTON_VARIANTS.ACCENT}>Register now</Button>
        </a>
      </Hero>
      <Hero alternative>
        <Germany />
        <Gap />
        <H2>
          <b>Made in Germany</b>
        </H2>
        <P $inheritColor>
          Contracts under German law. Lean, understandable and safe
        </P>
      </Hero>
      <Gap x={2} />
      <PageSection>
        <Center>
          <H2>
            <b>Sell</b>
            {` your account and `}
            <AccentText>
              <Typed
                strings={[
                  "invest in a new project.",
                  "go on a world tour.",
                  "make a dream come true.",
                ]}
                typeSpeed={120}
                backSpeed={50}
                loop
                smartBackspace={false}
              />
            </AccentText>
          </H2>
        </Center>
      </PageSection>
      <PageSection>
        <Grid $breakAt={768}>
          <Cell $s={6}>
            <FeatureList>
              {featuresTwo.map(({ Component, title, description }) => (
                <FeatureItem key={title}>
                  <Component $size={42} $color={colors.primary} />
                  <div>
                    <SmallP $inheritColor>
                      <b>{title}</b>
                    </SmallP>
                    <Gap x={0.5} />
                    <SmallP>{description}</SmallP>
                  </div>
                </FeatureItem>
              ))}
            </FeatureList>
          </Cell>
          <Cell $s={6}>
            <H2>
              <b>Sell</b> your account
            </H2>
            <Gap />
            <P>
              Most owners of a print-on-demand account have no idea what their
              account is actually worth. You only see the monthly sales and have
              never thought about whether they could be sold.
            </P>
            <Gap />
            <P>
              Just like companies, accounts can also be rated. The value is
              derived from the sales that are likely to be achieved in the
              future, as well as from numerous other factors.
            </P>
          </Cell>
        </Grid>
      </PageSection>
      <Gap x={2} />
      <Hero>
        <H2>
          Do you want to <b>buy</b> an Account?
          <br />
          Then register!
        </H2>
        <Gap />
        <a target="_blank" href="https://www.facebook.com/groups/flipstorm">
          <Button variant={BUTTON_VARIANTS.ACCENT}>Register now</Button>
        </a>
      </Hero>
      <Gap x={2} />
      <PageSection>
        <Center>
          <H1>Any questions?</H1>
          <Gap />
          <P>
            Not all questions can be answered through a simple website. If you
            would like to find out more, submit our contact form. We will then
            contact you as soon as possible.
          </P>
          <Gap />
          <a target="_blank" href="https://www.facebook.com/groups/flipstorm">
            <Button variant={BUTTON_VARIANTS.ACCENT}>Contact us</Button>
          </a>
        </Center>
      </PageSection>
      <Gap x={2} />
    </div>
  );
};

export default Homepage;
