import { gql } from "@apollo/client";

export const GET_LISTING = gql`
  query GetListing($id: String!) {
    listing(id: $id) {
      id
      name
      description
      seoDescription
      monthlyProfit
      monthlyNet
      lastYearProfit
      dataCurrency
      niches
      ableToSellAds
      endDate
      verified
      reliable
      minimumBidAmount
      minimumBidIncrement
      graphics {
        name
        list {
          label
          value
        }
      }
      sellPrice
      currency
      documents {
        src
        title
      }
      image {
        src
        title
      }
      reasonForSale
      type {
        id
        name
      }
      owner {
        image {
          src
          title
        }
        displayName
        bio
      }
      topBid {
        value
        currency
        owner {
          id
        }
      }
    }
  }
`;

export const POST_BID = gql`
  mutation PostBid($currency: String!, $listingId: String!, $value: Float!) {
    postBid(currency: $currency, listingId: $listingId, value: $value) {
      id
    }
  }
`;
