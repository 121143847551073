export const formatCurrency = (
  val,
  currency,
  options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }
) =>
  val !== undefined
    ? val.toLocaleString(undefined, {
        style: "currency",
        currency,
        ...options,
      })
    : "";

export const getCurrencySymbol = (currency) =>
  formatCurrency(0, currency, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
    .replace(/\d/g, "")
    .trim();
