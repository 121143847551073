import React, { useEffect } from "react";
import { formatCurrency } from "shared/formatters/currency";
import { H2, H6, P } from "shared/ui/headings";
import Modal from "shared/ui/modal";
import { Gap } from "shared/ui/gap";
import { BidHeader } from "../../bid-banner/styled";
import { ModalBody, ModalContent } from "../styled";
import { Separator } from "shared/ui/separator";
import Countdown from "../../bid-banner/countdown";
import { Button, BUTTON_VARIANTS, PlainLink } from "shared/ui/button";
import { Center } from "shared/ui/center";
import { useMutation } from "@apollo/client";
import { POST_BID } from "../../queries";
import { withModalManagement } from "shared/modal-manager/index";
import { LISTING_MODALS } from "../constants";
import Loading from "shared/ui/loading";

const BidConfirmationModal = (props) => {
  const {
    modalProps,
    value,
    currency,
    listingId,
    endDate,
    modalActions,
  } = props;
  const [postBid, meta] = useMutation(POST_BID, {
    onCompleted: () =>
      modalActions.triggerModal(LISTING_MODALS.bidConfirmed, {
        value,
        endDate,
        currency,
      }),
  });

  const handleClick = () => {
    postBid({
      variables: {
        currency,
        listingId,
        value,
      },
    });
  };

  return (
    <Modal {...modalProps}>
      <ModalContent>
        <BidHeader>
          <H6>Your bid is</H6>
          <Gap x={0.5} />
          <H2>
            <b>{formatCurrency(value, currency)}</b>
          </H2>
          <Gap x={0.5} />
          <Separator $opac />
          <Gap x={0.5} />
          <Countdown endDate={endDate} />
        </BidHeader>
        <ModalBody>
          <P>
            By confirming your bid, you're committing to buying this business if
            you're the winning bidder, and you accept the{" "}
            <PlainLink href="/terms" target="_blank">terms & conditions</PlainLink>,
          </P>
          <Gap />
          <Center>
            {meta.loading ? (
              <Loading />
            ) : (
              <Button
                $noWrap
                variant={BUTTON_VARIANTS.PRIMARY}
                onClick={handleClick}
              >
                Confirm bid
              </Button>
            )}
          </Center>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default withModalManagement(BidConfirmationModal);
