import BidConfirmationModal from "./bid-confirmation/index";
import { LISTING_MODALS } from "./constants";
import BidConfirmedModal from "./bid-confirmed/index";
import WhatHappensModal from "./what-happens/index";

export const modalRegistry = {
  [LISTING_MODALS.bidConfirmation]: BidConfirmationModal,
  [LISTING_MODALS.bidConfirmed]: BidConfirmedModal,
  [LISTING_MODALS.whatHappens]: WhatHappensModal,
};
