import React from "react";
import styled from "styled-components";

const FlagBox = styled.div`
  border-radius: 10px;
  width: 150px;
  overflow: hidden;
`;

const GermanyLine = styled.div`
  width: 100%;
  height: 30px;
  background: ${({ $color }) => $color};
`;
export const Germany = (props) => (
  <FlagBox>
    <GermanyLine $color={"#000000"} />
    <GermanyLine $color={"#dd0000"} />
    <GermanyLine $color={"#ffce00"} />
  </FlagBox>
);
