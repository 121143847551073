import React from "react";
import { StyledFooter, Logo } from "./styled";
import { SmallP } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";
import { Center } from "shared/ui/center";
import { Grid, Cell } from "shared/ui/grid";
import { Link } from "react-router-dom";

const Footer = (props) => {
  return (
    <StyledFooter>
      <Grid $align="flex-start" $breakAt={768}>
        <Cell $s={4}>
          <SmallP $inheritColor>
            <b>Flipstorm – Christian Heidorn, Felix Schuldt &amp; Jorge Lopes GbR</b>
          </SmallP>
          <SmallP>
            Moltkestr. 14
            <br />
            76646 Bruchsal
            <br />
            Deutschland
            <br />
            E-Mail: info@flipstorm.co
          </SmallP>
        </Cell>
        <Cell $s={4}>
          <SmallP>
            Head office: Felix Schuldt
            <br />
            Tax number: 30023/98121
            <br />
            VAT-ID: is applied for
            <br />
            Sales tax identification number according to § 27 a 
            <br />
            Value added tax law: is applied for
            <br />
            <br />
            Platform of the EU Commission for online dispute resolution:{" "}
            <a
              href="https://ec.europa.eu/consumers/odr"
              rel="noreferrer"
              target="_blank"
            >
              https://ec.europa.eu/consumers/odr
            </a>
          </SmallP>
        </Cell>
        <Cell $s={4}>
          <SmallP>
            We are not obligated to participate in a dispute resolution procedure before a consumer arbitration board, but are generally prepared to do so.
          </SmallP>
          <br />
          <SmallP>
            Member of the initiative "Fairness in trade".
            <br />
            More information:{" "}
            <a
              href="https://www.fairness-im-handel.de"
              target="_blank"
              rel="noreferrer"
            >
              https://www.fairness-im-handel.de
            </a>
          </SmallP>
          <br />
          <SmallP>
            <Link to="/terms">Terms and conditions</Link>
          </SmallP>
        </Cell>
      </Grid>
      <Gap x={4} />
      <Center>
        <Logo src={"/images/logo_primary.png"} />
        <SmallP>
          Copyright {new Date().getFullYear()}. All rights reserved.
        </SmallP>
      </Center>
    </StyledFooter>
  );
};

export default Footer;
