import styled, { createGlobalStyle, css } from "styled-components";
import { NavLink } from "react-router-dom";
import { List } from "shared/ui/plain-list";
import { colors } from "shared/ui/colors";

export const LinkList = styled(List)`
  display: flex;
  align-items: center;
`;

export const StyledNavLink = styled(NavLink)`
  padding: 6px 12px;
  text-decoration: none;
  font-weight: 700;
  color: ${colors.midGray};
  position: relative;
  &:after {
    content: "";
    position: absolute;
    height: 2px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: width 0.2s ease-out;
    width: 0%;
    background: ${colors.primary};
  }
  &.active,
  &:hover {
    color: ${colors.primary};
  }

  &.active:after {
    width: 100%;
  }
`;

export const GlobalStyle = createGlobalStyle`
  body {
    padding-top: 80px;
  }
  @media screen and (max-width: 480px) {
    body {
      padding-top: 48px;
    }
  }
`;

export const StyledHeader = styled.header`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: all 0.2s ease-out;
  height: ${({ $shrink }) => ($shrink ? "50px" : "80px")};
  background: white;
  box-shadow: 0 1px 2px 4px rgba(0, 0, 0, 0.15);
  z-index: 5;
  padding: 0 calc(50% - 576px);
  @media screen and (max-width: 1200px) {
    padding: 0 0 0 12px;
  }
  @media screen and (max-width: 480px) {
    height: 48px;
  }

`;
export const LogoHolder = styled.div`
  flex-grow: 1;
  padding: 18px 0;
`;

export const Logo = styled.img`
  transition: all 0.2s ease-out;
  max-height: 39px;
  height: 100%;
  @media screen and (max-width: 480px) {
    display: none;
  }
`;

export const LogoMobile = styled.img`
  max-height: 39px;
  height: 100%;
  display: none;
  @media screen and (max-width: 480px) {
    display: block;
  }
`;
