import styled from "styled-components";
import { colors } from "shared/ui/colors";
import { ListItem, List } from "../../../shared/ui/plain-list";

export const Hero = styled.section`
  background: ${({ alternative }) =>
    alternative ? colors.secondary : colors.primary};
  color: ${colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px calc(50% - 365px);
  text-align: center;
  @media screen and (max-width: 768px) {
    padding: 70px 15px;
  } 
`;

export const HeroImage = styled.img`
  max-width: 100%;
`

export const PageSection = styled.section`
  padding: 50px 15px;
  max-width: 1200px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    padding: 25px 15px;
  }
`;

export const Logo = styled.img`
  width: 100%;
  border-radius: 6px;
  transition: opacity 0.2s ease-out;
  :hover {
    opacity: 0.8;
  }
`;

export const AccentText = styled.b`
  color: ${colors.primary};
  @media screen and (max-width: 768px) {
    display: block;
  }
`;

export const FeatureList = styled(List)`
  border: 1px solid ${colors.lightGray};
  border-radius: 5px;
  padding: 30px 35px;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.15);
  margin: 12px 0;
`;

export const FeatureItem = styled(ListItem)`
  &:not(:last-child) {
    margin-bottom: 35px;
  }
  > i {
    margin-right: 15px;
    flex: 15% 0 0;
  }
  display: flex;
  align-items: flex-start;
`;
