import React from 'react'
import microdown from 'micro-down'
import { PageTitle } from 'shared/ui/page-title'
import { Helmet } from 'react-helmet'
import { PageContent } from 'shared/ui/page-content'
import { H1 } from 'shared/ui/headings'

const termContent = `
Thank you for using Flipstorm!

When we say ”company”, “we”, “our”, “us”, ”service” or ”services” in this document, we are referring to Flipstorm.

**Note**: The following Terms of Service apply to all visitors of the domains [flipstorm.co](http://flipstorm.co/), [flipstorm.com](http://flipstorm.com/) as well as all registered users of the services we provide.

We may update these Terms of Service in the future. Whenever we make a significant change to our policies, we will also announce them.

When you use our service, now or in the future, you are agreeing to the latest Terms of Service. That’s true for any of our existing and future products and services and all features that we add over time. There may be times where we do not exercise or enforce any right or provision of the Terms of Service; in doing so, we are not waiving that right or provision. These terms do contain a limitation of our liability.

If you do not agree to these Terms of Service, do not use this service. Violation of any of the terms below may result in the termination of your account. That’s a broad statement and it means you need to place a lot of trust in us. We do our best to deserve that trust by being very open and transparent with all of our registered and vetted members.

## Account terms

Visiting and browsing the Flipstorm website does not entitle you to any particular service or rights. You are simply browsing the website and that's it. We trust that you understand this.

If you wish to register with us, you will go through a formal vetting process during which we are required to collect some of your personal information in order to determine whether you are a suitable candidate for our service. The vetting process in itself is not an indicating of your suitability and we may decline your request.

Should you be accepted to our service, we will need to keep personal data on file as it is necessary in order to reasonably facilitate transactions as well as in maintaining a high level of security for all participants of our service. If you do not successfully pass the vetting process, any and all documents you have provided us with can be deleted on your request.

You are responsible for maintaining the security of your account and password. Flipstorm cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.

You are responsible for any activity that occurs under your account (even by others who have their own logins under your account).

You may not use our service for any illegal purpose or to violate any laws in your jurisdiction.

You must provide your legal full name and a valid email address in order to complete the signup process.

You must be a human. Accounts registered by bots or other automated methods are not permitted.

## Transactions

All transactions are simply *facilitated* by Flipstorm. Any contract and as well as the exchange of payments between a buyer and a seller are at the sole discretion of those parties and any legal requirements arising from such transactions only apply to the buyer and seller. Flipstorm is strictly *not* a contract party of any purchase agreement.

Commissions due by the seller to Flipstorm are subject to a separate consulting/facilitation agreement in which the seller agrees to pay Flipstorm a pre-determined percentage of the final net sales price in the event of a successful sale.

Contracts are binding, verbal or written, irrespective of wether in formal or informal form.

## Cancellation and termination

You are solely responsible for properly canceling your account. An email to cancel your account is not considered cancellation. Rather, we require further confirmation of your identity before we can fulfil this request.

We reserve the right to suspend or terminate your account and refuse any and all current or future use of the service for any reason at any time. Such termination of the service will result in the deactivation or deletion of your account or your access to your account. Flipstorm reserves the right to refuse service to anyone for any reason at any time. We have this clause because statistically speaking, out of the thousands of people, there may be at least one doing something nefarious.

Verbal, physical, written or other abuse (including threats of abuse or retribution) of any service customer, company employee or officer may result in immediate account termination.

## Modifications to the service and prices

We reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, any part of the service with or without notice.

Sometimes we change the pricing structure for our services. When we do that, we tend to exempt existing customers from those changes. However, we may choose to change the prices for existing customers. If we do so, we will give prior notice. We may also post a notice about changes on our blog or the affected services themselves.

Flipstorm shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of the service.

## Content ownership, copyright and trademark

You are solely responsible for any content and other material that you submit, publish, transmit, email, or display on, through, or with the service.

We claim no intellectual property rights over the material you provide to the service. All site data remains yours.

You may provide us with feedback, suggestions, and ideas about the service. You agree that we own all rights to use and incorporate the feedback you provide in any way, including in future enhancements and modifications to the service, without payment or attribution to you.

You must not modify another website so as to falsely imply that it is associated with Flipstorm. The look and feel of the service is copyright© to Flipstorm. All rights reserved. "Flipstorm", the Flipstorm logo and any other product or service name or slogan displayed on the service are trademarks of the company and may not be copied, imitated or used, in whole or in part, without the prior written permission of the company or the applicable trademark holder. You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the service, use of the service, or access to the service without the express written permission by the company.

## Privacy and security of your data

We take many measures to protect and secure your data through backups, redundancies, and encryption. You entrust us with your data and we take that trust to heart. You agree that Flipstorm may process your data as required for the reasonable use of our website and services.

Each party agrees to handle the other party’s data in accordance with (i) all applicable laws; and (ii) privacy and security measures reasonably adequate to preserve the other party data’s confidentiality and security.

You own all right, title, and interest to your personal data. We obtain no rights from you to your data. We do not collect and analyze personal information from web users and we do not use behavioral insights to sell advertisements. We will never sell or share your site data to any third-parties.

You agree to comply with all applicable laws including all privacy and data protection regulations.

You agree not to use the service to send sensitive information of a 3rd party to the company where unauthorized disclosure could cause material, severe, or catastrophic harm or impact to the company, any data subjects or third-parties. Sensitive information includes, but is not limited to credit card information, passport numbers, government issued identification numbers, financial account information, real time geolocation and personally identifiable information (PII). PII is information that could be used on its own to directly identify, contact, or precisely locate an individual.

## General conditions

Your use of Flipstorm is at your sole risk. The service is provided on an “as is” and “as available” basis. We do take uptime of our service seriously.

We design our services with care, based on our own experience and the experiences of customers who share their time and feedback. However, there is no such thing as a service that pleases everybody. We make no guarantees that our services will meet your specific requirements or expectations.

We also test all of our features extensively before shipping them. As with any software, our services inevitably have some bugs. We track the bugs reported to us and work through priority ones, especially any related to security or privacy. Not all reported bugs will get fixed and we don’t guarantee completely error-free services.

Technical support is provided by email. Email responses are provided on the reasonable effort basis without guaranteed response time.

We as humans can access your data to help you with support requests you make and to maintain and safeguard Flipstorm to ensure the security of your data and the service as a whole.

We use third party vendors to provide the necessary hardware, storage, payment processing and related technology required to run the Services.

## Liability

We mention liability throughout these Terms but to put it all in one section:

You expressly understand and agree that Flipstorm shall not be liable, in law or in equity, to you or to any third party for any direct, indirect, incidental, lost profits, special, consequential, punitive or exemplary damages, including, but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if the company has been advised of the possibility of such damages), resulting from: (i) the use or the inability to use the services; (ii) the cost of procurement of substitute goods and services resulting from any goods, data, information or services purchased or obtained or messages received or transactions entered into through or from the services; (iii) unauthorized access to or alteration of your transmissions or data; (iv) statements or conduct of any third party on the service; (v) or any other matter relating to this Terms of Service or the services, whether as a breach of contract, tort (including negligence whether active or passive), or any other theory of liability.

In other words: choosing to use our services does mean you are making a bet on us. If the bet does not work out, that’s on you, not us. We do our darnedest to be as safe a bet as possible through careful management of the business; investments in security, infrastructure, and talent; and in general giving a damn. If you choose to use our services, thank you for betting on us.

This agreement shall be governed by the laws of the Federal Republic of Germany, and the courts of the Federal Republic of Germany shall have exclusive jurisdiction to hear and determine all issues that may arise under or in relation to this agreement.

All contractual agreements are made in English language. Translations in other language may be made available for the sole purpose of clarifying the legal consequences of such agreements. However, the original English contracts remain the sole legal basis of any agreements between you and us. Exceptions to this clause will be clearly stated in any such document.

## Contact us

If you have a question about any of the Terms of Service, please  [contact us](mailto:info@flipstorm.co).
`

const Terms = props => {
  return (
    <>
      <Helmet>
        <title>Terms of Service</title>
      </Helmet>
      <PageTitle>
        <H1>Flipstorm Terms of Service</H1>
      </PageTitle>
      <PageContent dangerouslySetInnerHTML={{__html: microdown.parse(termContent)}} />
    </>
  )
}

export default Terms
