import React from "react";
import About from "./about";
import ReasonForSale from "./reason-for-sale";
import Niches from "./niches";
import Performance from "./performance";
import Graphics from "./graphics";
import Documents from "./documents";
import Seller from "./seller";

const ListingBody = (props) => {
  return (
    <>
      <About />
      {props.children}
      <ReasonForSale />
      <Niches />
      <Performance />
      <Graphics />
      <Documents />
      <Seller />
    </>
  );
};

export default ListingBody;
