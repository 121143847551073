import React from "react";
import { useQuery } from "@apollo/client";
import Helmet from "react-helmet";
import { Hero, PageSection } from "../homepage/styled";
import { Grid, Cell } from "shared/ui/grid";
import { H1 } from "shared/ui/headings";
import Loading from "shared/ui/loading";
import { GET_LISTINGS } from "./queries";
import { useState } from "react";
import { assocPath } from "ramda";
import FilterPanel from "./filter-panel";
import Listings from "./listings";
import { Root } from "./styled";

const MarketPlace = (props) => {
  const { loading, error, data } = useQuery(GET_LISTINGS);

  const [filterState, setFilterState] = useState({
    types: {},
  });

  const applyFilter = (statePath, value) =>
    setFilterState(assocPath(statePath, value));

  return (
    <Root>
      <Helmet>
        <title>Marketplace</title>
        <meta name="description" content="Marketplace" />
      </Helmet>
      <Hero>
        <H1>Marketplace</H1>
      </Hero>
      <PageSection>
        {loading ? (
          <Loading />
        ) : error ? (
          <pre>{JSON.stringify(error)}</pre>
        ) : (
          <Grid $align="flex-start">
            <Cell $s={2}>
              <FilterPanel
                onChange={applyFilter}
                types={data.listingTypes}
                filterState={filterState}
              />
            </Cell>
            <Cell $s={10}>
              <Listings filters={filterState} listings={data.listings} />
            </Cell>
          </Grid>
        )}
      </PageSection>
    </Root>
  );
};

export default MarketPlace;
