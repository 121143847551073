import styled from "styled-components";
import { colors } from "../../../../shared/ui/colors";
import { Button } from "../../../../shared/ui/button";
import { Link } from "react-router-dom";

export const ListingCard = styled(Link)`
  color: black;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: white;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.15);
  padding: 15px 15px 15px 130px;
  position: relative;
  min-height: 130px;
  overflow: hidden;
`;

export const ListingImage = styled.img`
  position: absolute;
  top: 15px;
  left: 15px;
  width: 100px;
`;

export const ListingPrice = styled.span`
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px 15px;
  font-weight: 700;
  z-index: 1;
  :before {
    content: "";
    position: absolute;
    box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.15);
    left: 0;
    width: 120%;
    height: 100%;
    transform: skew(22deg);
    background: ${colors.accent};
    z-index: -1;
    top: 0;
  }
`;

export const ListingButton = styled(Button).attrs({
  as: "div",
})`
  align-self: flex-end;
`;
