import React, { useState, useEffect, forwardRef } from "react";
import styled, { css } from "styled-components";
import { useInView } from "react-intersection-observer";

const SIZE_BREAKPOINTS = {
  xl: 1600,
  l: 1201,
  m: 1024,
  s: 768,
  xs: 480,
};

const Img = styled.img`
  max-width: 100%;
  margin: auto;
  display: block;
  ${(props) =>
    props.cover
      ? css`
          width: 100%;
          height: 100%;
          object-fit: cover;
        `
      : ""}
`;

const Loader = forwardRef((props, ref) => {
  const {
    source,
    viewports,
    viewportsRatio,
    alt,
    imgProps = {},
    defaultImage,
    ratio,
    shouldLoad = true,
    cover,
  } = props;
  const getViewport = (size) => {
    if (size >= SIZE_BREAKPOINTS.xl) return "xxl";
    else if (size >= SIZE_BREAKPOINTS.l) return "xl";
    else if (size >= SIZE_BREAKPOINTS.m) return "l";
    else if (size >= SIZE_BREAKPOINTS.s) return "m";
    else if (size >= SIZE_BREAKPOINTS.xs) return "s";
    else return "xs";
  };

  const autoFillViewport = (desiredViewport, obj) => {
    if (obj.hasOwnProperty(desiredViewport)) {
      return obj[desiredViewport];
    } else if (desiredViewport == SIZE_BREAKPOINTS.xs) {
      return null;
    }
    const nextKey = Object.keys(
      SIZE_BREAKPOINTS
    ).reduce((acc, item, idx, arr) =>
      desiredViewport == arr[idx - 1] ? item : acc
    );
    return autoFillViewport(nextKey, obj);
  };

  const [sourceLoaded, setSourceLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [currentViewport, setCurrentViewport] = useState(
    getViewport(typeof window !== "undefined" ? window.innerWidth : 1920)
  );

  const currentSource = viewports
    ? viewports.hasOwnProperty(currentViewport)
      ? viewports[currentViewport]
      : autoFillViewport(currentViewport, viewports) || source
    : source;

  const currentRatio = viewportsRatio
    ? viewportsRatio.hasOwnProperty(currentViewport)
      ? viewportsRatio[currentViewport]
      : autoFillViewport(currentViewport, viewportsRatio) || ratio
    : ratio || "1:1";

  useEffect(() => {
    let mounted = true;
    if (shouldLoad) {
      new Promise((resolve, reject) => {
        const img = new Image();
        img.src = currentSource;
        img.onload = resolve;
        img.onerror = reject;
      })
        .then(() => {
          if (mounted) {
            setSourceLoaded(true);
            setHasError(false);
          }
        })
        .catch((e) => {
          if (mounted) {
            console.log(e);
            setSourceLoaded(true);
            setHasError(true);
          }
        });
    }
    return () => (mounted = false);
  }, [currentSource, shouldLoad]);

  useEffect(() => {
    let timeout = null;
    const listener = () => {
      clearTimeout(timeout);
      timeout = setTimeout(
        () => setCurrentViewport(getViewport(window.innerWidth)),
        200
      );
    };
    window.addEventListener("resize", listener);
    return () => window.removeEventListener("resize", listener);
  }, []);

  if (sourceLoaded) {
    return (
      <Img
        src={hasError ? defaultImage : currentSource}
        alt={alt}
        cover
        {...imgProps}
      />
    );
  } else {
    return (
      <div ref={ref} className="image-loading">
        <span
          style={{
            display: "block",
            width: "100%",
            paddingTop:
              "calc(" +
              currentRatio.split(":")[1] +
              " / " +
              currentRatio.split(":")[0] +
              " * 100%)",
          }}
        />
      </div>
    );
  }
});

const ImageLoader = ({ shouldLoad = true, ...props }) => {
  const [ref, inView, entry] = useInView({
    treshold: 0.05,
  });

  return (
    <Loader {...props} ref={ref} shouldLoad={inView ? shouldLoad : false} />
  );
};

export default ImageLoader;
