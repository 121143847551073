import React from 'react'
import { P } from "shared/ui/headings";
import { Gap } from "shared/ui/gap";
import { formatCurrency } from "shared/formatters/currency";
import { Detail, KeyDetails } from "../header/styled";
import { useListing } from '../context';

const Performance = () => {
  const { monthlyNet, monthlyProfit, lastYearProfit, dataCurrency, currency } = useListing()
  const actualCurrency = dataCurrency || currency

  return <>
    <P>
      <b>Performance</b>
    </P>
    <Gap x={0.5} />
    <KeyDetails>
      <Detail title="Monthly profit">
        {formatCurrency(monthlyProfit, actualCurrency)}
        /mo
      </Detail>
      {false && <Detail title="Monthly net">
        {formatCurrency(monthlyNet, actualCurrency)}
        /mo
      </Detail>}
      <Detail title="Revenue last 12 months">
        {formatCurrency(lastYearProfit, actualCurrency)}
      </Detail>
    </KeyDetails>
    <Gap x={2} />
  </>
}

export default Performance