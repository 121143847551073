import { findIndex, propEq } from 'ramda'
import React from 'react'
import { H6 } from 'shared/ui/headings'
import Tabs from 'shared/ui/tabs/index'
import FaqItem from './faq-item'

const Faq = props => {
  const { onChange, faqs, faqTypes, selectedTypeId } = props

  const selectedIndex = findIndex(propEq('id', selectedTypeId), faqTypes)

  if (faqs.length === 0 ) return null
  return (
    <Tabs
      onChange={onChange}
      items={faqTypes.map(type => ({
        label: <H6>{type.name}</H6>,
        key: type.id,
        component: <div>{faqs.filter(faq => faq.type.id === type.id).map(faq => <FaqItem {...faq} />)}</div>
      }))}
      currentTab={selectedIndex === -1 ? 0 : selectedIndex}
    />
  )
}

export default Faq