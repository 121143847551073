import React from "react";

const FilterPanel = (props) => {
  const isChecked = (type) => props.filterState.types[type.id];
  return (
    <div>
      Types
      {props.types.map((type) => (
        <div key={type.id}>
          <label>
            <input
              onChange={(e) =>
                props.onChange(["types", type.id], e.target.checked)
              }
              checked={isChecked(type)}
              type="checkbox"
            />
            {type.name}
          </label>
        </div>
      ))}
    </div>
  );
};
export default FilterPanel;
