import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
  	padding: 0;
    font-family: 'DM Sans', 'Open Sans', 'Helvetica', 'Arial', sans-serif;
    font-variant-ligatures: common-ligatures;
  }
  *, *:before, *:after { box-sizing: border-box; }
  b { font-weight: 600; }
  #root {overflow: hidden;}
  a { text-decoration: none; }
  @font-face {
    font-family: 'iconsmind';
    src:url('/fonts/iconsmind.eot?#iefix-rdmvgc') format('embedded-opentype');
    src:url('/fonts/iconsmind.woff') format('woff'),
      url('/fonts/iconsmind.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;

export default GlobalStyle;
