import styled, { css } from "styled-components";

const mobileBreak = `@media screen and (max-width: 768px)`

const baseStyles = (fontSize, lineHeight, fontWeight, letterSpacing = 0) => css`
  font-size: ${fontSize}px;
  line-height: ${lineHeight}px;
  font-weight: ${fontWeight};
  letter-spacing: ${letterSpacing}px;
  margin: 0;
`;

export const H1 = styled.h1`
  ${baseStyles(44, 58, 300, -0.44)}
  ${mobileBreak} {
    ${baseStyles(20, 26, 300, -0.44)}
  } 
`;

export const H2 = styled.h2`
  ${baseStyles(33, 45, 300)}
  ${mobileBreak} {
    ${baseStyles(18, 20, 300)}
  }
`;

export const H5 = styled.h5`
  ${baseStyles(24, 28, 700)}
  ${mobileBreak} {
    ${baseStyles(18, 20, 700)}
  }
`;

export const H6 = styled.h6`
  ${baseStyles(20, 22, 400)}
  ${mobileBreak} {
    ${baseStyles(18, 20, 400)}
  }
`;

export const P = styled.p`
  ${baseStyles(16, 20, 400)}
  ${mobileBreak} {
    ${baseStyles(14, 18, 400)}
  }
`;

export const SmallP = styled.p`
  ${baseStyles(14, 24, 400)}
  ${mobileBreak} {
    ${baseStyles(13, 24, 400)}
  }
  white-space: pre-line;
`;

export const Note = styled.p`
  ${baseStyles(11, 13, 400)}
`;
